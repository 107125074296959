<template>
  <div id="reset">
    <div class="container p-4" style="width: 100%; max-width: 30rem;">
      <h4>Смена пароля</h4>
      <br />
      <form action="#" method="post" enctype="multipart/form-data">
        <!-- <input
          type="email"
          placeholder="Электронная почта"
          id="Email"
          class="form-control-dark mb-4"
          v-model="email"
          style="width: 27rem"
        /> -->

        <input
          type="password"
          placeholder="Новый пароль"
          id="Password_reset"
          class="log-field form-control-dark mb-4"
          v-model="password_reset"
          style="width: 100%"
        />

        <input
          type="password"
          placeholder="Новый пароль еще раз"
          id="Password_repeat_reset"
          class="log-field form-control-dark mb-4"
          v-model="password_repeat_reset"
          style="width: 100%"
        />

        <input
          type="button"
          class="login-btn btn btn-dark btn-block mb-4"
          @click="reset"
          value="Сменить пароль"
          style="width: 100% !important;"
        />
      </form>
      <p class="text-danger" v-if="msg">{{ msg }}</p>
    </div>
  </div>
</template>

<script>
import { reset, accessforgot } from "@/api/auth.js";

export default {
  data() {
    return {
      //email: "",
      password_reset: "",
      password_repeat_reset: "",
      msg: "",
    };
  },
  created() {
    localStorage.setItem("token", this.$route.params.token);
    accessforgot().catch(() => {
      localStorage.removeItem("token");
      this.$router.push("/auth/link-expired");
    });
  },
  methods: {
    async reset() {
      try {
        //console.log(this);
        const credentials = {
          //email: this.email,
          password_reset: this.password_reset,
          password_repeat_reset: this.password_repeat_reset,
        };
        const response = await reset(credentials);
        this.$router.push("/auth/password-changed");
      } catch (error) {
        this.msg = error.response.data.message;
      }
    },
  },
};
</script>

<style scoped>
.login-btn {
  text-transform: capitalize;
  color: black;
  padding: 0.75rem 3rem;
  height: auto !important;
  font-size: 18px !important;
  line-height: 28px !important;
  border-radius: 14px !important;
  background-color: #ffcf66;
  font-weight: 600;
}
.log-field {
  color: black;
  border: 1px solid #bdbdbd;
  border-radius: 14px !important;
  padding: 10px;
}

.log-field:focus-visible {
  outline: none !important;
}
#reset {
  margin-top: 50px;
  font-family: "Montserrat";
  background-color: transparent !important;
  background-image: url("../../assets/img/_landing/karakul2.svg") !important;
  background-position: 100% 135% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: 30% !important;
  z-index: 0;
  margin-top: 50px;
  min-height: 95vh;
}
</style>
